<script>
  const currentYear = new Date().getFullYear();
</script>

<footer class="site-footer">
  <div class="footer-container">
    <nav class="footer-nav">
      <a href="/" class="footer-logo">
        <img
          src="/img/icons/icon-aminifar.svg"
          alt="SvelteOnePager logo"
          class="footer-icon"
        />
      </a>

      <a
        href="https://www.google.com/maps/dir//De+Hoge+Brug+46,+6581+AJ+Malden/data=!4m7!4m6!1m1!4e2!1m2!1m1!1s0x47c70f4a0620d7cb:0x2fa13eca8e4e8ed3!3e0?sa=X&ved=2ahUKEwi3vbCzq9uEAxXO2QIHHQ23B1YQox16BAgOEAA"
        target="_blank">De Hoge Brug 46, 6581 AJ Malden</a
      >
      <a
        href="mailto:info@aminifar.com"
        target="_blank"
        rel="noopener noreferrer">info@aminifar.com</a
      >
      <a href="tel:0031620101375" target="_blank" rel="noopener noreferrer"
        >+31(0) 6 20 101 375</a
      >
      <p class="extra-info">BTW: NL8586.69.316B.01</p>
      <a
        href="https://www.kvk.nl/bestellen/#/71322272000015558649"
        target="_blank"
        rel="noopener noreferrer"
        class="kvk">KVK: 71322272</a
      >
    </nav>
    <div class="footer-version">
      ©
      <a
        href="https://faktor22.nl"
        target="_blank"
        aria-label="Bezoek Faktor 22"
        >Faktor 22 | <span id="currentYear">{currentYear}</span></a
      >
    </div>
  </div>
</footer>

<style>
  .site-footer {
    color: #000;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1280px;
  }

  .footer-logo img {
    width: 25px; /* Adjust based on actual logo size */
  }

  .footer-nav {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .footer-nav a {
    font-size: 14px;
    margin: 0 10px;
    text-decoration: underline;
    color: inherit;
  }

  .footer-version {
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 13px;
  }

  .footer-version a {
    color: #fff;
    text-decoration: none;
  }

  .extra-info {
    margin-left: 10px;
  }

  .kvk {
    margin-left: 20px !important;
  }

  @media (max-width: 1000px) {
    .footer-container {
      flex-direction: column;
      text-align: left;
      margin-bottom: 20px;
      justify-content: left;
      align-items: flex-start;
    }

    .footer-nav {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      align-items: flex-start;
    }

    .kvk {
      margin-left: 0px !important;
      margin-bottom: 20px !important;
    }
    p {
      font-size: 14px;
    }

    .footer-version {
      margin-top: 20px;
      font-size: 12px;
    }

    .extra-info {
      margin: 20px 0 0 0;
    }
  }

  @media (max-width: 650px) {
    .footer-nav a {
      display: block;
      margin: 5px 0;
    }
  }
</style>
