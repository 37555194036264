<script>
  import { onMount } from "svelte";
  import Header from "./components/Header.svelte";
  import Footer from "./components/Footer.svelte";

  // State to control which section is open in the accordion
  let openSection = null;

  // Toggle visibility of accordion sections
  function toggleSection(section) {
    openSection = openSection === section ? null : section;
  }

  // Setup draggable slider functionality on mount
  onMount(() => {
    const slider = document.querySelector(".horizontal-scroll-container");
    if (!slider) return;

    let isDown = false;
    let startX;
    let scrollLeft;

    // Mouse down event to initiate dragging
    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    // Clean up drag state on mouse leave and up
    const stopScroll = () => {
      isDown = false;
      slider.classList.remove("active");
    };
    slider.addEventListener("mouseleave", stopScroll);
    slider.addEventListener("mouseup", stopScroll);

    // Mouse move event to handle dragging
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 1;
      slider.scrollLeft = scrollLeft - walk;
    });

    let copy = document.querySelector(".logos-slide").cloneNode(true);
    document.querySelector(".logos").appendChild(copy);

    /*======================================
    	           Scroll to top
    =======================================*/

    document.addEventListener("DOMContentLoaded", (event) => {
      // Scroll to top button
      const scrollTopBtn = document.getElementById("scrollTopBtn");

      scrollTopBtn.addEventListener("click", () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      });

      // Toon de knop alleen als de gebruiker naar beneden gescrold heeft
      window.addEventListener("scroll", () => {
        if (window.scrollY > 300) {
          // De knop verschijnt nadat je 300px naar beneden hebt gescrold
          scrollTopBtn.style.display = "block";
        } else {
          scrollTopBtn.style.display = "none";
        }
      });
    });
  });
</script>

<Header />

<main>
  <div class="bg-hero"></div>

  <!-- Hero Section -->
  <section class="hero" aria-label="Introduction">
    <div class="hero-section-1">
      <h1 class="mainHeading" id="mainHeading">
        <span class="heading-title">Aminifar</span><br />
        Uw partner in technisch beheer en onderhoud
      </h1>
      <p class="heading-subtitle">
        Gelegen in het hart van Malden, biedt Aminifar betrouwbare oplossingen
        voor al uw technisch beheer en vastgoedonderhoud.
      </p>
      <a href="#about-us" class="cta"
        >Meer info <i class="material-icons">arrow_forward</i></a
      >
    </div>
    <div class="hero-section-2">
      <img
        src="/img/bg-img-hero.png"
        alt="Illustrative background showing Aminifar capabilities"
        class="hero-image animate"
      />
    </div>
  </section>

  <!-- Services Section -->
  <section id="services" aria-label="Services">
    <div class="info-section">
      <div class="info-explain">
        <h2>
          Aminifar, heeft een brede expertise voor het beheer van uw vastgoed en
          installaties.
        </h2>
        <p>Beleid en beheer vastgoed en facilities:</p>
      </div>
      <div class="scroll-wrapper">
        <div class="horizontal-scroll-container" role="list">
          <div class="service">
            <img
              src="/img/icons/aminifar-onderhoudsbeleid.svg"
              alt="alt-text-icon"
              class="icon"
            />
            <h3>Onderhoudsbeleid</h3>
          </div>
          <div class="service">
            <img
              src="/img/icons/aminifar-onderhoudsplannen.svg"
              alt="alt-text-icon"
              class="icon"
            />
            <h3>Onderhoudsplannen</h3>
          </div>
          <div class="service">
            <img
              src="/img/icons/aminifar-begeleiding.svg"
              alt="alt-text-icon"
              class="icon"
            />
            <h3>Begeleiding</h3>
          </div>
          <div class="service">
            <img
              src="/img/icons/aminifar-vastgoedopname.svg"
              alt="alt-text-icon"
              class="icon"
            />
            <h3>Vastgoedopname</h3>
          </div>
          <div class="service">
            <img
              src="/img/icons/aminifar-dagelijks-onderhoud.svg"
              alt="alt-text-icon"
              class="icon"
            />
            <h3>Dagelijks Onderhoud</h3>
          </div>
          <div class="service">
            <img
              src="/img/icons/aminifar-beleidsuitvoering.svg"
              alt="alt-text-icon"
              class="icon"
            />
            <h3>Beleidsuitvoering</h3>
          </div>
        </div>
      </div>
      <div id="about-us"></div>
    </div>
  </section>

  <!-- About Us Section -->
  <section id="about-us" class="about-us">
    <h3>Over ons</h3>
    <p>
      Welkom bij Aminifar Vastgoed Beheer B.V., uw toegewijde partner in het
      beheer en onderhoud van vastgoed en technische installaties. Sinds onze
      oprichting in 1996 hebben we ons gepositioneerd als een vooraanstaand
      bedrijf in de sector, bekend om onze innovatieve en totaaloplossingen die
      zorgen voor optimaal beheer van uw vastgoedportfolio.
    </p>
    <p>
      Onze diensten omvatten een uitgebreid scala aan beheers- en
      onderhoudswerkzaamheden, afgestemd op de unieke behoeften van elk vastgoed
      en elke installatie. Van routinematig onderhoud tot complexe technische
      uitdagingen, Aminifar Vastgoed Beheer B.V. staat garant voor kwaliteit,
      betrouwbaarheid en duurzaamheid.
    </p>
    <p>
      Ons team bestaat uit zorgvuldig geselecteerde professionals die elk hun
      eigen specialisme inbrengen. Deze diversiteit aan vaardigheden stelt ons
      in staat om een breed spectrum aan diensten te leveren en te zorgen voor
      het hoogste niveau van klanttevredenheid. Bij Aminifar begrijpen we het
      belang van uw investering en streven we ernaar om de waarde en prestaties
      van uw vastgoed en technische installaties te maximaliseren.
    </p>
  </section>

  <!-- Logo's Section -->
  <section id="logos" class="logos">
    <div class="logos-slide">
      <img src="/img/logos/logo-vandewaterbouw.svg" alt="Logo 1" />
      <img src="/img/logos/logo-woonwaarts.svg" alt="Logo 2" />
      <img src="/img/logos/logo-dominicanessenvanneerbosch.png" alt="Logo 3" />
      <img
        src="/img/logos/logo-strijbosch-thunnissen-makelaars.svg"
        alt="Logo 4"
      />
      <img src="/img/logos/logo-houwen-makelaars.svg" alt="Logo 5" />
      <img src="/img/logos/logo-gommers.jpg" alt="Logo 6" />
      <img src="/img/logos/logo-romijnders.svg" alt="Logo 10" />
      <img src="/img/logos/logo-zwartjes.png" alt="Logo 7" />
      <img src="/img/logos/logo-securitas.svg" alt="Logo 8" />
      <img
        src="/img/logos/Logo-van-t-hoff-elektrotechniek-wijchen.png"
        alt="Logo 9"
      />

      <img src="/img/logos/logo-plieger.svg" alt="Logo 11" />
      <img src="/img/logos/logo-bouwmaat.svg" alt="Logo 12" />
      <img src="/img/logos/logo-pontmeyer.svg" alt="Logo 13" />
      <img src="/img/logos/logo-warmteservice.svg" alt="Logo 14" />
      <img src="/img/logos/logo-raab-karcher.svg" alt="Logo 15" />
      <img src="/img/logos/logo-sigma.svg" alt="Logo 16" />
    </div>
  </section>

  <!-- Accordion Section -->
  <section id="faq" class="accordion-section">
    <h3>FAQ</h3>
    <div class="toggle">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="header" on:click={() => toggleSection("section1")}>
        Wat biedt Aminifar?
        <img
          src="/img/icons/icon-down-2.svg"
          alt="Arrow accordion 1"
          class:rotate={openSection === "section1"}
        />
      </div>
      {#if openSection === "section1"}
        <div class="content">
          Aminifar is gespecialiseerd in het technisch beheer en onderhoud van
          vastgoed en technische installaties. Wij bieden totaaloplossingen die
          variëren van routinematig onderhoud tot het oplossen van complexe
          technische uitdagingen, allemaal met het doel om de waarde en
          prestaties van uw vastgoed te maximaliseren.
        </div>
      {/if}
    </div>
    <div class="toggle">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="header" on:click={() => toggleSection("section2")}>
        Waar is Aminifar gevestigd?
        <img
          src="/img/icons/icon-down-2.svg"
          alt="Arrow accordion 2"
          class:rotate={openSection === "section2"}
        />
      </div>
      {#if openSection === "section2"}
        <div class="content">
          Aminifar is gevestigd in het hart van Malden. Vanuit onze centrale
          locatie bieden wij betrouwbare oplossingen voor klanten in de regio en
          daarbuiten.<br /><br />

          De Hoge Brug 46, 6581 AJ Malden
        </div>
      {/if}
    </div>
    <div class="toggle">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="header" on:click={() => toggleSection("section3")}>
        Hoe lang bestaat Aminifar al?
        <img
          src="/img/icons/icon-down-2.svg"
          alt="Arrow accordion 3"
          class:rotate={openSection === "section3"}
        />
      </div>
      {#if openSection === "section3"}
        <div class="content">
          Aminifar Vastgoed Beheer B.V. is opgericht in 1996 en heeft sindsdien
          een reputatie opgebouwd als vooraanstaand bedrijf in de sector van
          vastgoedbeheer en -onderhoud.
        </div>
      {/if}
    </div>
    <div class="toggle">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="header" on:click={() => toggleSection("section4")}>
        Kan Aminifar helpen bij het opstellen van een onderhoudsbeleid?
        <img
          src="/img/icons/icon-down-2.svg"
          alt="Arrow accordion 4"
          class:rotate={openSection === "section4"}
        />
      </div>
      {#if openSection === "section4"}
        <div class="content">
          Ja, Aminifar helpt bij het opstellen van onderhoudsbeleid en het
          ontwikkelen van meerjarige onderhoudsplannen. Wij bieden ook
          begeleiding bij de uitvoering van deze plannen om de duurzaamheid en
          functionaliteit van uw vastgoed te waarborgen.
        </div>
      {/if}
    </div>
    <div class="toggle">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="header" on:click={() => toggleSection("section5")}>
        Hoe kan ik contact opnemen met Aminifar?
        <img
          src="/img/icons/icon-down-2.svg"
          alt="Arrow accordion 5"
          class:rotate={openSection === "section5"}
        />
      </div>
      {#if openSection === "section5"}
        <div class="content">
          U kunt contact opnemen met Aminifar door ons een e-mail te sturen op
          <a href="mailto:info@aminifar.com">info@aminifar.com</a> of te bellen
          op <a href="tel:0031620101375">+31(0) 6 20 101 375</a>. Wij staan
          klaar om al uw vragen te beantwoorden en u te ondersteunen bij uw
          vastgoedbeheerbehoeften.
        </div>
      {/if}
    </div>
  </section>

  <!-- Additional Section -->
  <section class="additional-content">
    <p>
      Als uw partner in technisch beheer en onderhoud werken we voortdurend aan
      het verbeteren van onze diensten en het aanbieden van op maat gemaakte
      oplossingen die aan uw specifieke eisen voldoen. Onze toewijding aan
      uitmuntendheid, gecombineerd met onze uitgebreide kennis en ervaring,
      maakt Aminifar Vastgoed Beheer B.V. de ideale keuze voor al uw
      vastgoedbeheerbehoeften.
    </p>
    <p>
      Ontdek vandaag nog hoe Aminifar Vastgoed Beheer B.V. u kan ondersteunen
      bij het beheren en optimaliseren van uw vastgoed en technische
      installaties. Neem contact met ons op voor meer informatie over onze
      diensten en laat ons u helpen uw vastgoeddoelstellingen te bereiken.
    </p>
  </section>

  <!-- Contact Section -->
  <section id="contact" class="contact">
    <h3>Neem contact met ons op</h3>
    <p>
      Wilt u meer weten over onze diensten of een afspraak maken? Neem dan
      gerust contact met ons op. U kunt ons bereiken via telefoon of e-mail. We
      helpen u graag verder met uw vragen.
    </p>

    <a href="mailto:info@aminifar.com" class="email"
      >Stuur een e-mail <i class="material-icons">arrow_forward</i></a
    >
  </section>

  <button id="scrollTopBtn" title="Ga naar boven">&#8679;</button>
</main>

<Footer />

<style>
  :root {
    --primary-color: #e5f5ff;
    --secondary-color: #f4f4f4;
    --color-black: #000;
    --color-white: #fff;
  }

  :target {
    padding-top: 120px;
    margin-top: -120px;
  }

  main {
    padding: 1em;
    margin: 0 auto;
  }

  .bg-hero {
    background-color: var(--primary-color);
    width: 100%;
    height: 700px;
    position: absolute;
    top: -20px;
    left: 0;
    z-index: -1;
  }

  .hero {
    display: flex;
    justify-content: space-between;
    gap: 100px;
    margin-top: 160px;
  }

  .hero-section-2 {
    align-content: center;
    display: flex;
  }

  .hero-image {
    width: 450px;
    max-width: 600px;
  }

  .cta {
    font-size: 20px;
    margin-top: 10px;
    display: flex;
    width: fit-content;
    align-items: center;
    background-color: #ffdf9e;
    border-radius: 20px;
    padding: 6px 20px;
  }

  .mainHeading {
    font-size: 50px;
    font-weight: 600;
    color: var(--color-black);
  }

  .heading-title {
    font-size: 22px;
    font-weight: 300;
  }

  .heading-subtitle {
    font-weight: 300;
    font-size: 22px;
    color: var(--color-black);
  }

  .icon {
    width: 35px;
    margin-bottom: 5px;
    align-items: center;
  }

  .horizontal-scroll-container {
    scrollbar-width: none;
  }

  .horizontal-scroll-container::-webkit-scrollbar {
    display: none;
  }

  .horizontal-scroll-container {
    margin: 0 15px;
    display: flex;
    gap: 30px;
    flex: 1;
    overflow-y: hidden;
    transition: all 0.2s;
    transform: scale(0.98);
    will-change: transform;
    user-select: none;
    cursor: pointer;
  }

  .scroll-wrapper {
    position: relative;
    overflow: hidden;
  }

  .info-section {
    display: flex;
    align-items: center;
    margin-top: 60px;
    background-color: var(--color-white);
    padding: 30px;
    border-radius: 20px;
    box-shadow: 10px 10px 20px rgba(25, 51, 94, 0.1);
  }

  .info-explain {
    flex: 0 0 38%;
    margin-right: 40px;
  }

  .info-explain h2 {
    font-size: 25px;
  }

  @keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  .logos {
    margin-top: 50px;
    overflow: hidden;
    white-space: nowrap;
  }

  .logos-slide {
    display: inline-block;
    animation: 60s slide infinite linear;
    filter: grayscale(100%);
  }

  .logos:hover .logos-slide {
    animation-play-state: paused;
  }

  .logos:before,
  .logos:after {
    position: relative;
    display: inline-block;
    top: 0;
    width: 175px;
    height: 50px;
    content: "";
    z-index: 1;
  }

  .material-icons {
    margin-left: 10px;
  }

  .logos-slide img {
    max-width: 200px;
    height: 50px;
    margin: 0 35px;
  }

  .service {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    background-color: var(--secondary-color);
    padding: 30px 15px;
    border-radius: 15px;
    text-align: center;
    height: 90px;
    min-width: 230px;
  }

  .service h3 {
    margin: 5px;
    font-size: 20px;
  }

  .about-us,
  .accordion-section,
  .additional-content,
  .contact {
    max-width: 800px;
    margin: 70px auto;
  }

  .accordion-section .toggle {
    background-color: var(--color-white);
    border-radius: 10px;
    margin-bottom: 12px;
    border: 1px solid #d1deee;
  }

  .accordion-section .toggle .header {
    text-align: left;
    padding: 15px;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .accordion-section .toggle .header img {
    width: 15px;
    height: 15px;
    transition: transform 0.3s ease;
    transform: rotate(-90deg);
  }

  .accordion-section .toggle .header img.rotate {
    transform: rotate(0deg);
  }

  .accordion-section .toggle .content {
    padding: 15px;
    border-top: 1px solid #d1deee;
  }

  .animate {
    animation: AnimatieKeyframe 3s ease-in-out infinite;
  }

  @keyframes AnimatieKeyframe {
    0%,
    100% {
      transform: rotate(0deg);
    }
    50% {
      transform: translateX(10px) translateY(10px) translateZ(0) rotateZ(0deg);
    }
  }

  .contact {
    padding: 30px;
    background-color: var(--color-black);
    color: var(--color-white);
    border-radius: 20px;
  }

  .email {
    color: #000;
    background-color: var(--color-white);
    padding: 10px 20px;
    border-radius: 10px;
    text-decoration: none;
    font-weight: 600;
    margin-top: 20px;
    display: flex;
    align-items: center;
    width: fit-content;
  }

  /*======================================
	           Scroll to Top 
=======================================*/

  #scrollTopBtn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px 12px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    font-size: 18px;
    background: #0073e6;
    color: #fff;
    z-index: 1;
  }

  /* Extra stijl voor wanneer de gebruiker over de knop zweeft */
  #scrollTopBtn:hover {
    color: #000;
    background-color: #e7d7ff;
  }

  @media only screen and (max-width: 1000px) and (min-width: 470px) {
    .bg-hero {
      height: 520px !important;
    }
  }

  @media (max-width: 1000px) {
    :target {
      padding-top: 0px;
      margin-top: 0px;
    }

    main {
      max-width: 100%;
      width: 100%;
      padding: 0;
    }
    .bg-hero {
      display: none;
    }
    .hero {
      display: block;
      background-color: var(--primary-color);
      padding: 15px;
      border-radius: 10px;
      margin: 90px -10px 0 -10px;
    }
    .hero-section-2 {
      justify-content: end;
      margin: -50px 0 10px 0;
    }
    .hero-image {
      width: 150px;
    }
    .mainHeading {
      margin: 0;
      font-size: 40px;
    }
    .heading-subtitle {
      font-size: 22px;
    }
    .about-us h3 {
      font-size: 25px;
    }
    .info-section {
      border-radius: 0;
      padding: 0;
      margin-top: 0px;
      background-color: unset;
      box-shadow: unset;
    }
    .accordion-section .toggle .header {
      font-size: 17px;
    }
    .accordion-section h3 {
      font-size: 25px;
    }
    .info-section {
      flex-direction: column;
    }
    .info-explain {
      margin-right: 0;
    }
    #services {
      margin-top: 30px;
    }
    .service {
      padding: 30px 10px;
    }
    .scroll-wrapper {
      width: 107%;
    }
    .horizontal-scroll-container {
      gap: 15px;
    }
    .contact {
      width: 85%;
      padding: 25px;
    }
    .contact h3 {
      margin-top: 0;
    }
  }
</style>
