<header class="site-header">
  <div class="header-container">
    <!-- Logo linked to the top of the page or home -->
    <a href="#mainHeading" class="logo-link">
      <img src="../img/logo-aminifar.svg" alt="Logo Aminifar" class="logo" />
    </a>

    <!-- Navigation Links -->
    <nav class="site-navigation">
      <ul class="nav-list">
        <li><a href="#about-us" class="nav-link">Over ons</a></li>
        <li>
          <a href="#services" class="nav-link">Technisch beheer & Onderhoud</a>
        </li>
        <li><a href="#faq" class="nav-link">FAQ</a></li>
        <!-- More list items as needed -->
      </ul>
    </nav>
    <!-- Call Action -->

    <a href="tel:00316201013758" class="contact"
      ><i class="material-icons">call</i>
      Bel ons</a
    >
  </div>
</header>

<style>
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.2s ease-in-out;
    box-shadow: none;
    margin: 1.5rem auto;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: #f4f4f4;
    background: #fff;
    width: 1280px;
    max-width: 1280px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  .material-icons {
    font-size: 18px;
    margin-right: 8px;
  }

  .logo {
    width: 215px;
    padding: 1.3rem;
  }

  .logo-link {
    align-content: center;
    display: flex;
  }

  .nav-list {
    list-style: none;
    display: flex;
    margin-right: 20px;
  }

  .nav-list li {
    margin: 0 10px;
  }

  .site-navigation {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.3rem;
  }

  .nav-link {
    margin-right: 1rem;
    font-size: 18px;
    color: #000;
  }

  .contact {
    background-color: #0073e6;
    color: #fff;
    padding: 7px 12px;
    border-radius: 10px;
    margin: 1.3rem;
    display: flex;
    align-items: center;
  }

  @media (max-width: 1000px) {
    .header-container {
      width: 100%;
      max-width: 100%;
      position: fixed;
      left: 0;
      transform: none;
      margin-bottom: 50px !important;
      border-radius: 0;
      top: -25px;
      border: unset;
      box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.05);
    }
    .logo {
      width: 160px;
      padding: 1rem 25px;
    }

    .site-navigation {
      display: none;
    }
  }
</style>
